function slider() {
    const areaSwiper = new Swiper(".area__swiper", {
        slidesPerView: 1,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "6%",
        navigation: {
            nextEl: ".area__arrow--next",
            prevEl: ".area__arrow--prev",
        }
        
    })

    const eventsSwiper = new Swiper(".events__swiper", {
        slidesPerView: 1,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "6%",
        navigation: {
            nextEl: ".events__arrow--next",
            prevEl: ".events__arrow--prev",
        }
        
    })
    const materialsSwiper = new Swiper(".partners__swiper--materials", {
        slidesPerView: 4,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "20px",
    })
    const biologySwiper = new Swiper(".partners__swiper--biology", {
        slidesPerView: 4,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "20px",
    })
    const microSwiper = new Swiper(".partners__swiper--micro", {
        slidesPerView: 4,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "20px",
    })
    const dataSwiper = new Swiper(".partners__swiper--data", {
        slidesPerView: 4,
        observer: true,
        observeSlideChildren: true,
        updateOnWindowResize: true,
        updateOnWindowResize: true,
        observeParents: true,
        speed: 1000,
        spaceBetween: "20px",
    })
}

module.exports = slider;